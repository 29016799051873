import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Restrict } from '../components/Restrict';
import Modal from 'react-responsive-modal';
import ReactTable from "react-table";
import Select from 'react-select';
import { paymentPortalColumns, paymentHistoryColumns } from '../config/column_fields'
import '../styles/paymentportal.css';
import { fetchAllPayment, paymentPortalMapping, newPayment, updatePayment, fetchHistory, getComments, createNewComment, editPaymentsInBulk, bulkUploadPayments } from '../actions/paymentPortal';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { csvPaymentData } from '../config/csv_fields';
import queryString from 'query-string';


class PaymentPortal extends Component {
  constructor(props) {
    super(props)
    this.reactTable = React.createRef();
    this.state = {
      historyColumns: paymentHistoryColumns,
      tableColumns: paymentPortalColumns,
      openNewPaymentModal: false,
      error: false,
      tableData: [],
      allMapping: [],
      category: [],
      subCategory: [],
      status: [],
      newPaymentMap: {},
      edit: false,
      oldPaymentMap: {},
      openHistoryModal: false,
      history: [],
      historyStatus: false,
      loading: true,
      userAccess: [],
      openCommentModal: false,
      allComments: [],
      newComment: "",
      allComments: [],
      row: {},
      startDate: 0,
      endDate: 0,
      statusChosen: [],
      categoryChosen: [],
      subCategoryChosen: [],
      modeChosen: [],
      bankChosen: [],
      statusFilter: [],
      csvData: [],
      selectedOrderIds: {},
      bulkAssignObj: {},
      statusArr: [],
      page:0,
      limit:500,
      filters:{
        startDate:'',
        endDate:''
      },
      paymentData:[],
      user:{}

    };
    this.adminAccessLevel = "222222"
  }

  componentDidMount() {
    if (this.props.user) {
      if (this.props.user.level >= 1600) {
        let user = this.props.user;
        this.setState({
          user:user
        })
        let parsed = queryString.parse(this.props.location.search) || {};
        let defaultFilters = {};
        if(parsed.category) {
          console.log("category", parsed.category);
          defaultFilters.category = [ parsed.category ];
        }
        this.props.fetchAllPayment(0,500,[],defaultFilters,user)
        this.props.paymentPortalMapping(user)
        paymentPortalColumns.map(val => {

          if (val.accessor == 'abc') {
            val.Cell = row => {
              return (
                <div>
                  <button className="btn btn-warning" style={{ marginRight: '15px' }} name="edit" onClick={(e) => this.openCreatePaymentModal(e, row.original)}>Edit</button>
                  <button className="btn btn-primary" style={{ marginRight: '15px' }} name="edit" onClick={(e) => this.openCommentModal(e, row.original)}>Comment</button>
                  <button className="btn btn-info" onClick={(e) => this.handleHistory(e, row.original)}>History</button>
                </div>
              )
            }
          }
          if (val.accessor === "checkbox") {
            val.Header = row => {
              return (
                <div>
                  <input type="checkbox" onChange={(e) => { this.handleAllCheckbox(e, row) }} checked={this.state.selectAll ? 'checked' : ''} ></input>
                </div>
              )
            };
            val.Cell = row => (
              <div>
                <input type="checkbox" onChange={(e) => { this.handleCheckbox(e, row.original.paymentKey, row.original, row.index) }} checked={this.state.selectedOrderIds[row.original.paymentKey] ? 'checked' : ''} ></input>
              </div>
            );
            val.Filter = () => {
              let selectedCheckboxCount = Object.keys(this.state.selectedOrderIds || {}).filter((val) => this.state.selectedOrderIds[val] == 1);
              return (
                <div>{selectedCheckboxCount.length}</div>
              )
            }
          };
        })
      }
    }
  }

  componentWillReceiveProps(props) {
    let allMapping = props.allMapping ? props.allMapping : {};
    if (props.allPayments && Object.keys(allMapping).length > 0) {
      // let tableData =  props.allPayments;
      let category = props.allMapping.category.map((val) => { return { value: val, label: val } });
      let mode = props.allMapping.mode.map((val) => { return { value: val, label: val } });
      let statusFilter = props.allMapping.status.map((val) => { return { value: val, label: val } });
      let bank = props.allMapping.bank.map((val) => { return { value: val, label: val } });
      let status = props.allMapping.status;
      let tableData = props.allPayments.map((payment) => {
        payment.viewDate = moment(parseInt( payment.paymentDate)).format("YYYY-MM-DD");
        payment.paymentDate = parseInt(payment.paymentDate)
         // payment.referenceNumberView = 2
        payment.referenceNumberView = (payment.referenceNumber || "").toString().replace(/\n|\t|"|'/g, ' ');
        return payment
      });
      tableData = JSON.parse(JSON.stringify(tableData))
      this.setState({
        tableData: tableData,
        tableDataCopy: tableData,
        paymentData:tableData,
        allMapping: allMapping,
        category: category,
        mode,
        bank,
        userAccess: props.access,
        loading: false,
        allComments: props.comments,
        statusFilter: statusFilter,
        status: status,
        statusArr: props.allMapping.status
        // csvData : tableData
      })
    }

    if (props.history) {
      this.setState({
        history: props.history,
        historyStatus: true
      })
    }
  }

  //========bulk edit functions=======//

  selectNewType = (name, options, value) => {
    return (
      <div style={{ width: '100%' }} >
        <select style={{ height: '35px' }}
          onChange={(e) => { this.handleBulkSelectChange(e) }}
          className="form-control" id="sel1" name={name} value={value}
        >
          <option>not selected</option>
          {(options ? options : []).map((e) => <option key={e}>{e}</option>)}
        </select>
      </div>
    )
  }

  handleBulkSelectChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      bulkAssignObj: {
        ...this.state.bulkAssignObj,
        [name]: value
      }
    })
  }

  handleAllCheckbox = (e, row) => {
    let page = this.reactTable.current.state.page;
    let pageSize = this.reactTable.current.state.pageSize;
    let start = page * pageSize;
    let end = (page * pageSize + pageSize);
    let actualEnd = (row.data.length > end) ? end : row.data.length;
    let data = row.data;
    let selectedOrderIds = {};
    if (!this.state.selectAll) {
      for (let i = start; i <= (actualEnd - 1); i++) {
        selectedOrderIds[data[i].paymentKey] = 1
      }
    }
    this.setState({
      selectedOrderIds: { ...selectedOrderIds },
      selectAll: !(this.state.selectAll)
    })
  };

  handleCheckbox = (e, key, row, index) => {
    if (e.nativeEvent.shiftKey == true) {
      let selectedOrderIds = { ...this.state.selectedOrderIds }
      let sortedArray = this.reactTable.current.state.sortedData;
      let lastSelectedIndex = -1;
      console.log(sortedArray);
      sortedArray.map((lead, index) => {
        if (selectedOrderIds[lead.paymentKey]) {
          lastSelectedIndex = index;
        }
      })
      if (lastSelectedIndex == -1) {
        selectedOrderIds[key] = 1
      } else {
        let currentIndex = sortedArray.findIndex(row => row.paymentKey == key);
        let [start, end] = currentIndex > lastSelectedIndex ? [lastSelectedIndex, currentIndex] : [currentIndex, lastSelectedIndex];
        for (let i = start; i <= end; i++) {
          selectedOrderIds[sortedArray[i].paymentKey] = 1;
        }
      }
      this.setState({
        selectedOrderIds: selectedOrderIds
      })
    } else {
      let status = this.state.selectedOrderIds[key];
      let selectedIdCopy = JSON.parse(JSON.stringify(this.state.selectedOrderIds))
      let selectedOrderIds = { ...selectedIdCopy, [key]: status ? 0 : 1, };
      this.setState({
        selectedOrderIds: selectedOrderIds,
        selectAll: this.state.selectAll && status ? false : this.state.selectAll
        // selectedOrderIds: [...selectedOrderIds]
      }, () => {
        console.log(selectedOrderIds)
      })
    }
  }
  handleBulkAssign = () => {
    let { tableDataCopy, selectedOrderIds, bulkAssignObj } = this.state;
    let bulkAssignObjCopy = JSON.parse(JSON.stringify(bulkAssignObj));
    let access = this.state.userAccess;
    console.log("selectedorderids", selectedOrderIds, "bulkAssignObj", bulkAssignObjCopy, "tableDataCopy", tableDataCopy);
    let user = this.props.user.phoneNumber;
    this.props.editPaymentsInBulk(tableDataCopy, selectedOrderIds, bulkAssignObjCopy, user, access);
  }
  //=======bulk edit ends=======//

  openCreatePaymentModal = (e, row) => {
    let buttonName = e.target.name;
    if (buttonName == "edit") {
      let category = row.category;
      let index = this.props.allMapping.category.indexOf(category);
      let userAccess = this.state.userAccess[index];
      let status = []
      if (userAccess == '1') {
        status = ['Deposited', 'Not Deposited']
      }
      if (userAccess == '2') {
        status = ['Cleared', 'Bounced', 'Deposited', 'Not Deposited']
      }
      this.setState({
        status: status.map((val) => { return { value: val, label: val } }),
        openNewPaymentModal: true,
        edit: true,
        newPaymentMap: row,
        oldPaymentMap: row,
      }, () => { })
    } else {
      this.setState({
        openNewPaymentModal: true,
        error: false
      })
    }
  }

  openBulkUploadModal = (e, row) => {
    console.log("open upload modal");
    this.setState({
      bulkUploadModal: true
    })
  }

  closeBulkUploadModal = (e, row) => {
    console.log("close upload modal");
    this.setState({
      bulkUploadModal: false
    })
  }

  closeNewPaymentModal = (e) => {
    this.setState({
      newPaymentMap: {},
      openNewPaymentModal: false,
      error: false,
      edit: false
    })
  }

  handleHistory = (e, row) => {
    let key = row.paymentKey
    let user = this.props.user.phoneNumber;
    this.props.fetchHistory(key, user);
    this.setState({
      openHistoryModal: true
    })
  }

  closeHistoryModal = (e) => {
    this.setState({
      openHistoryModal: false,
      historyStatus: false
    })
  }

  handleInputChange = (e) => {
    let { name, value } = e.target
    this.setState({
      newPaymentMap: {
        ...this.state.newPaymentMap,
        [name]: value,
      }
    })
  }

  handleSelectChange = (e, name) => {
    let { value, label } = e
    if (name == 'category') {
      let subCategoryArr = this.props.allMapping.subCategory[value];
      let index = this.props.allMapping.category.indexOf(value);
      let userAccess = this.state.userAccess[index];
      let status = []
      if (userAccess == '1') {
        status = ['Deposited', 'Not Deposited']
      }
      if (userAccess == '2') {
        status = ['Cleared', 'Bounced', 'Deposited', 'Not Deposited']
      }
      this.setState({
        newPaymentMap: {
          ...this.state.newPaymentMap,
          [name]: value
        },
        subCategory: subCategoryArr.map((val) => { return { value: val, label: val } }),
        status: status.map((val) => { return { value: val, label: val } })
      })
    } else {
      this.setState({
        newPaymentMap: {
          ...this.state.newPaymentMap,
          [name]: value
        },
      });
    }
  }

  inputType = (placeholder, type, name, value, disabled) => {
    return <input type={type} placeholder={placeholder} className="form-control payment-input" name={name} value={value} onChange={(e) => this.handleInputChange(e)} disabled={disabled} />
  }

  selectType = (disabled, placeholder, name, options, value) => {
    return (
      <div className="react-select-payment">
        <Select
          isSearchable
          onChange={(e) => this.handleSelectChange(e, name)}
          placeholder={placeholder}
          name={name}
          options={options}
          value={value}
          menuPlacement="auto"
          menuPosition="fixed"
          isDisabled={disabled}
          defaultValue={(this.state.edit && this.state.allMapping[name]) ? this.state.allMapping[name].map(val => ({ label: this.state.newPaymentMap[name], value: this.state.newPaymentMap[name] })) : ""}
        />
      </div>
    )
  }


  removingNullKeys = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }
    return obj
  }

  createNewPayment = (e) => {
    e.preventDefault();
    let tableData = this.props.allPayments;
    let access = this.state.userAccess;
    if (this.state.edit) {
      let oldFormData = { ...this.state.oldPaymentMap };
      let newFormData = this.removingNullKeys({ ...this.state.newPaymentMap });
      let user = this.props.user.phoneNumber;
      this.props.updatePayment(oldFormData, newFormData, tableData, access, user);

      this.setState({
        newPaymentMap: {},
        openNewPaymentModal: false,
        edit: false
      })
    } else {
      let formData = { ...this.state.newPaymentMap };
      let user = this.props.user.phoneNumber;
      formData = this.removingNullKeys(formData);
      formData.status = "Deposited";
      if (formData.category && formData.subCategory && formData.amount && (access.join('') == this.adminAccessLevel ? true : formData.id && formData.name) && this.validateChequeNumber(formData)) {
        this.props.newPayment(formData, user, tableData, access);
        this.setState({
          newPaymentMap: {},
          openNewPaymentModal: false,
          edit: false
        }, () => { })
      } else {
        this.setState({
          error: true
        })
      }
    }
  }

  validateChequeNumber = (data) => {
    console.log(data.mode)
    if (data.mode == "Cheque") {
      if (!data.chequeNumber) {
        return false;
      } else {
        return true;
      }
    } else {
      return true
    }
  }

  //============Comment=======//

  openCommentModal = (e, row) => {
    let id = row.paymentKey
    let user = this.props.user.phoneNumber;
    this.props.getComments(user, id)
    this.setState({
      openCommentModal: true,
      row: row
    })
  }
  closeCommentModal = (e, row) => {
    this.setState({
      openCommentModal: false,
      newComment: '',
      row: {}
    })
  }

  onCommentChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      newComment: value,
    })
  }

  commentSubmit = (e) => {
    let id = this.state.row.paymentKey
    let previousComments = [...this.state.allComments];
    // console.log("pcomment", previousComments)
    let newComment = this.state.newComment;
    newComment = newComment.trim();
    if (newComment.length > 0) {
      let user = this.props.user.phoneNumber;
      this.props.createNewComment(user, id, newComment, previousComments);
    }
    this.setState({
      newComment: ''
    })
  }

  //============Comment=======//

  //============Filter================//

  handleFilterInputChange = (e) => {
    let { name, value } = e.target;
    value = this.checkSelectString(value);
    value = this.removingNullKeys(value);
    let {filters} = this.state;
    filters = JSON.parse(JSON.stringify(filters))
    filters[name] = value;
    this.setState({
      filters
    }
    )
  }

  fetchMorePaymentData = ()=>{
    let { page, limit, paymentData, filters,user } = this.state;
    this.props.fetchAllPayment(page + 1,limit,paymentData,filters,user);
    this.setState({
        page: page + 1,
    })
  }

  fetchAllPaymentData = ()=>{
    let { page, paymentData, filters , user} = this.state;
    this.props.fetchAllPayment(page + 1,'all',paymentData,filters,user);
   
  }

  inputTypeFilter = (name, value, type, placeholder) => {
    return <input type={type} placeholder={placeholder} style={{ padding: "8px 8px" }} className="form-control" name={name} value={value} onChange={(e) => this.handleFilterInputChange(e)} />
  }

  handleMultiSelectChange = (selectedOption, name) => {
    let subCategoryArr = []
    let {filters} = this.state;
    filters = JSON.parse(JSON.stringify(filters));
    let value = (selectedOption || []).map(option => option.value);
    if (name == 'category') {
      (selectedOption || []).map((val) => {
        subCategoryArr = subCategoryArr.concat(this.props.allMapping.subCategory[val.value])
      })
      this.setState({
        subCategory: subCategoryArr.map((val) => { return { value: val, label: val } }),
      })
    }
    // console.log(selectedOption, name)
    filters[name] = value;
    this.setState({
        filters
    })
  }

  onApplyFilter = (e) => {
    let {filters,limit,user} = this.state;
    let {startDate,endDate} = filters
    startDate = startDate ? new Date(startDate + " 00:00:00").getTime() : 0;
    endDate = endDate ? new Date(endDate + " 00:00:00").getTime() + (86400000 - 1) : 0;
    // let statusChosen = this.state.statusChosen.map(val => val.value);
    // let modeChosen = this.state.modeChosen.map(val => val.value);
    // let categoryChosen = this.state.categoryChosen.map(val => val.value);
    // let subCategoryChosen = this.state.subCategoryChosen.map(val => val.value);
    // let bankChosen = this.state.bankChosen.map(val => val.value);
    // let tableData = JSON.parse(JSON.stringify(this.state.tableData));
    filters.paymentStartDate = startDate;
    filters.paymentEndDate = endDate
    console.log('filters', filters);
    this.props.fetchAllPayment(0, limit, [], filters,user);
    // this.props.fetchOrders(0,limit,[],filters, searchText);
    this.setState({
        page: 0,
    })
    // let filteredTable = tableData.filter(obj => {
    //   let paymentDate = obj.paymentDate ? new Date(obj.paymentDate).getTime() : 0;

    //   return ((statusChosen.length > 0 ? statusChosen.includes(obj.status) : true) && (modeChosen.length > 0 ? modeChosen.includes(obj.mode) : true) && (paymentDate >= startDate && paymentDate <= endDate) && (categoryChosen.length > 0 ? categoryChosen.includes(obj.category) : true) && (subCategoryChosen.length > 0 ? subCategoryChosen.includes(obj.subCategory) : true) && (bankChosen.length > 0 ? bankChosen.includes(obj.bank) : true))
    // })

  }

  //============Filter=======================//

  //==============commonly used functions ========//
  checkSelectString = (str) => {
    if (str == "not selected") {
      return str = ""
    }
    else {
      return str
    }
  }

  removingNullKeys = (obj) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName].length < 1 || obj[propName] == "" || obj[propName] == {}) {
        delete obj[propName];
      }
    }
    return obj
  }

  getDesiredMonth = (str) => {
    let startDate;
    let endDate;
    switch (str) {
      case "current":
        startDate = moment().startOf('month').format('YYYY-MM-DD');
        endDate = moment().endOf('month').format('YYYY-MM-DD');
        break;
      case "previous":
        startDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
        endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        break;
      case "quarter":
        startDate = moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD');
        endDate = moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD');
    }
    // console.log(startDate, endDate)
    let {filters} = this.state;
    filters = JSON.parse(JSON.stringify(filters))
    filters.startDate = startDate;
    filters.endDate = endDate;
    this.setState({
      filters
    }, () => {
      this.onApplyFilter()
    })
  }


  handleFileInputChange = (e) => {
    // console.log(file);
    let file = e.target.files[0];
    // console.log(file);
    this.setState({
      file: file
    })
  }


  fileSubmit = () => {
    // console.log("file submit");
    let { file = "" } = this.state;
    if(!file) {
      alert("Please upload file");
      return;
    }
    let user = this.props.user;
    this.props.bulkUploadPayments(file, user);
    this.setState({
      bulkUploadModal: false
    })
  }
  //==============commonly used functions ========/ /

  render() {
    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      },
      iconShow: {
        float: 'right',
        display: ''
      },
      iconHide: {
        float: 'right',
        display: 'none'
      },
      showError: {
        display: '',
        color: 'red',
      },
      hideError: {
        display: 'none',
      }
    };

    let parsed = queryString.parse(this.props.location.search) || {};

    if (this.props.user === null) {
      if(parsed.category) return <Redirect to={`/login?redirect_uri=paymentview?category=${parsed.category}`} />;
      return <Redirect to={"/login?redirect_uri=paymentview"} />;
    }

    if (this.props.user && this.props.user.level < 1600) {
      return (
        <div>
          <Restrict />
        </div>
      )
    }

    const modalStyles = {
      modal: {
        width: '40%',
        maxWidth: '40%',
        padding: '40px',
        background: '#f7f7f7',
        textAlign: 'center'
      }
    }
    return (
      <div style={{ "marginTop": "50px", backgroundColor: "#f7f7f7" }} className="container-fluid">
        <div style={{ textAlign: "center" }}>
          <h2 style={{ fontWeight: "bold" }}>Payment Portal</h2>
        </div>
        <div className="row v2-topbar-section">
          <div className="col-md-2">
            <label>Start Date</label>
            {this.inputTypeFilter("startDate", this.state.filters.startDate, "date",)}
          </div>
          <div className="col-md-2">
            <label>End Date</label>
            {this.inputTypeFilter("endDate", this.state.filters.endDate, "date")}
          </div>
          <div className="col-md-2">
            <label>Business Category </label>
            {selectCustom(this.state.category, "category", "Category", this.handleMultiSelectChange)}
          </div>
          <div className="col-md-2">
            <label>Sub Category</label>
            {selectCustom(this.state.subCategory, "subCategory", "Sub-category", this.handleMultiSelectChange)}
          </div>
          <div className="col-md-2">
            <label>Mode </label>
            {selectCustom(this.state.mode, "mode", "Mode", this.handleMultiSelectChange)}
          </div>
          <div className="col-md-2">
            <label>Bank </label>
            {selectCustom(this.state.bank, "bank", "Bank", this.handleMultiSelectChange)}
          </div>
          <div className="col-md-2" style={{ marginTop: "20px" }}>
            <label>Status</label>
            {selectCustom(this.state.statusFilter, "status", "Status", this.handleMultiSelectChange)}
          </div>
          <div className="col-md-2" style={{ marginTop: "20px" }}>
            <button className="btn btn-success v2SubmitButton" onClick={(e) => { this.onApplyFilter() }}>Apply Filter</button>
          </div>
          <div className="getDesiredDateDiv">
            <button class="desiredMonthBtn" onClick={(e) => { this.getDesiredMonth("current") }}>Current Month</button>
            <button class="desiredMonthBtn" onClick={(e) => { this.getDesiredMonth("previous") }}>Last Month</button>
            <button class="desiredMonthBtn" onClick={(e) => { this.getDesiredMonth("quarter") }}>Current Quarter</button>
          </div>
        </div>
        <div className="btnDiv">
          <div>
            <button className="btn btn-light btn-lg"  onClick={() => this.fetchMorePaymentData()}>More Payments</button>
          </div>

          <div>
            <button className="btn btn-light btn-lg" style={{ margin: '0 10px' }} onClick={() => this.fetchAllPaymentData()}>All Payments</button>

          </div>
          <div>
            <button className="btn btn-success btn-lg" onClick={this.openCreatePaymentModal}>New Payment</button>
          </div>
          <div>
            <button className="ml-3 btn btn-success btn-lg" onClick={this.openBulkUploadModal}>Upload</button>
          </div>
          <div className="csvDownloadBtn">
            {/* {console.log("csv1111", this.state.tableData)} */}
            <CSVLink filename={"paymentData.csv"} data={this.state.tableDataCopy ? this.state.tableDataCopy : []} headers={csvPaymentData} type="button" className="btn btn-light btn-lg">
              Download CSV <i className="fa fa-download"></i>
            </CSVLink>
          </div>
        </div>
        <div style={{ marginTop: "25px" }}>
          <div className="row" style={Object.values(this.state.selectedOrderIds).includes(1) ? style.show : style.hide}>
            <div className="col-md-2">
              <label>Order Status</label>
              {this.selectNewType("status", this.state.statusArr,)}
            </div>
            <div className="col-md-1" style={{ marginTop: "32px" }}>
              <button className="btn btn-primary btn" onClick={(e) => { this.handleBulkAssign(e) }}>Submit</button>
            </div>
          </div>
        </div>

        <div style={{ "marginTop": 20 }}>
          <ReactTable
            ref={this.reactTable}
            loading={this.state.loading}
            data={this.state.paymentData}
            columns={this.state.tableColumns}
            filterable={true}
            showPagination={true}
            sortable={true}
            style={{ textAlign: "center", backgroundColor: "#fff" }}
            defaultSorted={[{ id: "paymentDate", desc: true }]}
            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
          >
          </ReactTable>
        </div>

        <Modal open={this.state.openNewPaymentModal} onClose={this.closeNewPaymentModal} styles={modalStyles}>
          <div className="payment-create-form" >
            <form onSubmit={this.createNewPayment} className="form-control" style={{ border: '1px solid yellow', borderRadius: '5px' }} >
              <h3 style={{ marginTop: '15px', marginBottom: '30px', fontWeight: 'bold' }}>Create New Payment</h3>
              <p style={this.state.error ? style.showError : style.hideError}>Required fields Missing</p>
              <div className="form-group">
                <label className="newPaymentLabel">Payment Date</label>
                {this.inputType("", "date", 'paymentDate', this.state.newPaymentMap.paymentDate ? moment(this.state.newPaymentMap.paymentDate).format('YYYY-MM-DD') : "", (this.state.edit && this.state.userAccess.join("") != this.adminAccessLevel ? true : false))}
              </div>
              <div className="form-group">
                <label className="newPaymentLabel">Business Category <span style={{ color: 'red' }}>*</span></label>
                {this.selectType((this.state.edit && this.state.userAccess.join("") != this.adminAccessLevel ? true : false), "select category", "category", this.state.category)}
              </div>
              <div className="form-group">
                <label className="newPaymentLabel">Sub Category <span style={{ color: 'red' }}>*</span></label>
                <div className="react-select-payment">
                  <Select
                    isSearchable
                    onChange={(e) => this.handleSelectChange(e, 'subCategory')}
                    placeholder='choose sub-category'
                    name='subCategory'
                    options={this.state.subCategory}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    isDisabled={this.state.edit && this.state.userAccess.join("") != this.adminAccessLevel ? true : false}
                    defaultValue={(this.state.edit && this.state.allMapping.subCategory) ? Object.keys(this.state.allMapping.subCategory).map(val => ({ label: this.state.newPaymentMap.subCategory, value: this.state.newPaymentMap.subCategory })) : ""}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="newPaymentLabel">Quote Id <span style={this.state.userAccess.join("") != this.adminAccessLevel ? { color: 'red' } : { display: 'none' }}>*</span></label>
                {this.inputType("quote id", "text", 'id', this.state.newPaymentMap.id || "", (this.state.edit && this.state.userAccess.join("") != this.adminAccessLevel ? true : false))}
              </div>
              <div className="form-group">
                <label className="newPaymentLabel">Reference No. </label>
                {this.inputType("Reference No.", "text", 'referenceNumber', this.state.newPaymentMap.referenceNumber || "", (this.state.userAccess.join("") != this.adminAccessLevel ? true : false))}
              </div>
              <div className="form-group">
                <label className="newPaymentLabel">Customer Name <span style={this.state.userAccess.join("") != this.adminAccessLevel ? { color: 'red' } : { display: 'none' }}>*</span></label>
                {this.inputType("Full Name", "text", 'name', this.state.newPaymentMap.name || "", (this.state.edit && this.state.userAccess.join("") != this.adminAccessLevel ? true : false))}
              </div>
              <div className="form-group">
                <label className="newPaymentLabel">Amount <span style={{ color: 'red' }}>*</span></label>
                {this.inputType("Amount", "number", 'amount', this.state.newPaymentMap.amount || "", (false))}
              </div>
              <div className="form-group">
                <label className="newPaymentLabel">Mode</label>
                {this.selectType(false, "select payment mode", 'mode', this.state.mode)}
              </div>
              <div className="form-group" style={this.state.newPaymentMap.mode == "Cheque" ? style.show : style.hide}>
                <label className="newPaymentLabel">Cheque Number <span style={{ color: "red" }}>*</span></label>
                {this.inputType("Cheque no.", "number", 'chequeNumber', this.state.newPaymentMap.chequeNumber || "", (this.state.edit && this.state.userAccess.join("") != this.adminAccessLevel ? true : false))}
              </div>
              <div className="form-group">
                <label className="newPaymentLabel">Bank</label>
                {this.selectType(false, "select bank", 'bank', this.state.bank)}
              </div>
              <div className="form-group" style={this.state.edit ? style.show : style.hide}>
                <label className="newPaymentLabel">Status</label>
                {this.selectType(false, "select status", 'status', this.state.status)}
              </div>
              <div className="form-group" style={(this.state.newPaymentMap.subCategory == "Vendor Customer Payment" || (this.state.newPaymentMap.category == "Offline")) ? style.show : style.hide}>
                <label className="newPaymentLabel">Dealer Code <span style={this.state.newPaymentMap.category == "Offline" ? { color: 'red' } : { display: 'none' }}>*</span></label>
                {this.inputType("Dealer Code", "text", "dealerCode", this.state.newPaymentMap.dealerCode || "", (this.state.userAccess.join("") != this.adminAccessLevel ? true : false))}
              </div>
              <div className="new-payment-submit">
                <button className="btn btn-success" type="submit" value="Submit">Submit</button>
              </div>
            </form>
          </div>
        </Modal >

        {/*================== history Modal====================== */}

        <Modal open={this.state.openHistoryModal} onClose={this.closeHistoryModal}>
          <div style={{ textAlign: "center" }}>
            <h3 style={{ fontWeight: "bold" }}>History</h3>
            <ReactTable
              data={this.state.history}
              columns={this.state.historyColumns}
              filterable={true}
              showPagination={true}
              sortable={true}
              defaultPageSize={5}
              style={{ textAlign: "center" }}

            >
            </ReactTable>
          </div>

          {/* {(!this.state.historyStatus) ? "Loading..." : ((this.state.historyStatus && this.state.history.length > 0) ? (this.state.history.map((val) => {
            return (
              <div>
                <p><b>{val.employeeName}</b> {moment(val.changedOn).format("DD MMM,YYYY")} </p>
                <p><b>Category</b> : <b>{val.category}</b> &nbsp;&nbsp;&nbsp; <b>Field :</b> <b>{val.field}</b>&nbsp;&nbsp; Changed from &nbsp; <b> {val.from} </b> to <b> {val.to} </b> </p>
                <br></br>
              </div>
            )
          }
          )) : "No data found")} */}

        </Modal>
        <Modal open={this.state.openCommentModal} onClose={this.closeCommentModal}>
          <div className="container-fluid">
            <h3>Comments</h3>
            <hr></hr>
            {this.props.comments.map((comment) => {
              return (
                <div>
                  <p><b>{comment.employeeName} &nbsp;&nbsp; {moment(parseInt(comment.time)).format("DD MMM,YYYY")}</b>&nbsp;&nbsp;&nbsp;{comment.comment}</p>
                </div>
              )
            })}
            <div className="row">
              <div className="col-md-10">
                <textarea className="form-control" rows={2} placeholder="Add New Comment" name="newComment" type="text" id="newComment" onChange={(e) => this.onCommentChange(e)} value={this.state.newComment}></textarea>
              </div>
              <div className="col-md-2">
                <button style={{ marginTop: "15px" }} className="btn btn-success" onClick={this.commentSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal open={this.state.bulkUploadModal} onClose={this.closeBulkUploadModal}>
          <div className = "mt-3 mb-3" style={{ fontSize: "17px", fontWeight: "bold", textAlign: "center" }}>
            Upload CSV
          </div>
          <div className="row mt-3 mb-3">
            <div className="col-md-10">
              <input type="file" className="form-control" onChange={(e) => this.handleFileInputChange(e)} accept=".csv"></input>
            </div>
            <div className="col-md-2">
              <button className="btn btn-success" onClick={this.fileSubmit}>Submit</button>
            </div>
          </div>
        </Modal>
      </div >
    )
  }
}

const selectCustom = (options, name, placeholder, handleMultiSelectChange, defaultValue) => {
  const changeHandle = (value) => { handleMultiSelectChange(value, name) }
  return (
    <Select
      isMulti
      isSearchable
      onChange={(e) => { changeHandle(e) }}
      placeholder={placeholder}
      name={name}
      options={options}
      defaultValue={defaultValue}
    />
  )
}


function mapStateToProps(state) {
  // console.log(state)
  return {
    user: state.user,
    allPayments: state.allPayments.tableData ? state.allPayments.tableData.data : [],
    access: state.allPayments.tableData ? state.allPayments.tableData.access : [],
    allMapping: state.paymentPortalMapping ? state.paymentPortalMapping : {},
    history: state.paymentHistory ? state.paymentHistory : [],
    comments: state.paymentComments.commentData ? state.paymentComments.commentData : [],
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAllPayment, paymentPortalMapping, newPayment, updatePayment, fetchHistory, getComments, createNewComment, editPaymentsInBulk, bulkUploadPayments }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPortal);
