import React, { Component } from 'react';
import ReactTable from "react-table";
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import {UncontrolledAlert} from 'reactstrap';
import { CSVLink } from 'react-csv';
import { styleSheet } from '../styles/styleSheet';
import { checkExist, checkArrayExist } from '../helper/filterData';
import { EBCheck, LoanCheck } from '../helper/dealsHelper';
import { dateChanger, inputDateConverter } from '../helper/dateChanger';
import { dateRange, percentageRange } from '../helper/range';
import { columnsDealsCSV, csvErrorCSV } from '../config/csv_fields';
import { createDateObj } from '../helper/dateObj';
import { validateEmail } from '../helper/validate';

import { SalesViewCheckBox } from '../components/SalesViewCheckBox';

import { Filter } from './Filter';
import { columnsDeals } from '../config/column_fields';
import { changeToViewDate, changeTimeDifferenceToDays } from '../helper/dateChanger';

import '../styles/all.css';

const ebIssueMap = {
	"1": true,
	"2": true,
	"7": true,
	"3": true
}

export class DealsView extends Component {
	constructor(props) {
		super(props);
		this.csvLink = React.createRef();
		this.state = {
			dataGetOnce: false,
			stateList: [],
			stageList: [],
			subsidyList: [],
			stageMulti: [],
			stateMulti: [],
			subsidyMulti: [],
			vendorMulti: [],
			columnMulti: [],
			ebissueMulti: [],
			columnCheckBoxStatus: {},
			clumnChoosen: null,
			open: false,
			stateChoosen: [],
			subsidyChoosen: [],
			stageChoosen: [],
			columnChoosenArray: [],
			vendorChoosen: [],
			ebissueChoosen: [],
			siteVisitChoosen: [],
			cityChoosen : [],
			viewData: [],
			quoteCount: 0,
			systemsize: 0,
			columns: columnsDeals,
			startDate: "",
			endDate: "",
			startPercentage: -100,
			notificationTitle: null,
			notificationText: null,
			notificationTitleText: null,
			errorData: [],
			showError: false,
			openColumn: false,
			updateStatus: {
				status: false,
				text: ""
			},
			rmsInstalled: null,
			rmsStatus: null,
			downloadData: [],
			zunPrime: null,
			cityList : [],
			stage1List: [],
			stage2List: [],
			stage1Choosen: [],
			stage2Choosen: [],
			selectedVar: ''
		};

		// VARIABLE BINDIND
		this.customColumn = [];

		// FUNCTION BINDING
		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.onOpenCustomModal = this.onOpenCustomModal.bind(this);
		this.onCloseCustomModal = this.onCloseCustomModal.bind(this);
		this.onOpenColumnModal = this.onOpenColumnModal.bind(this);
		this.handleColumnChange = this.handleColumnChange.bind(this);
		this.onCloseColumnModal = this.onCloseColumnModal.bind(this);
		this.handleClickDownload = this.handleClickDownload.bind(this);
		this.handleNotificationChange = this.handleNotificationChange.bind(this);
		this.handleNotificationSubmit = this.handleNotificationSubmit.bind(this);
		this.handleSelectNotifitionChange = this.handleSelectNotifitionChange.bind(this);
		this.handleNotificationTitleCustomChange = this.handleNotificationTitleCustomChange.bind(this);
	}

	componentDidMount() {
		const { data, states, stages, subsidies, ebissues, choosenColumn, newStages = {}, selectedStage, netMeteringMap, selectedVariable, selectedStateList = "", selectedCityList = "" } = this.props;
		console.log("netMeteringMap", netMeteringMap);
		console.log("selectedStateList", selectedStateList);
		let selectedStateList1 = [];
		if(selectedStateList) selectedStateList1 = selectedStateList.split(",");
		let selectedCityList1 = [];
		if(selectedCityList) selectedCityList1 = selectedCityList.split(",");
		let [ selectedStageVars = '', selectedVar = '' ] = selectedStage.split('___');
		let [ selectedStage1 = '', selectedStage2 = '' ] = selectedStageVars.split('__');
		console.log("selectedvars", selectedStage1, selectedStage2, selectedVar, selectedStateList1, selectedCityList1);
		let columns = [...[{
			Header: row => {
				return (
					<div>
						<i className="fas fa-cog" style={{ "fontSize": "15px" }} onClick={this.onOpenColumnModal}></i>
					</div>
				)
			},
			accessor: "select",
			width: 50,
			sortable: false,
			filterable: false,
			showDefault: true,
		}], ...this.props.columns/*,
			...[{
				Header: "Net Metering",
				Row : row => {
					let obj= {}
					return (
						<div>
							<button className="btn btn-success" onClick={this.onOpenCustomModal(row.original.quoteid)}>ADD</button>
							<Modal open={this.state.openModal[row.original.userid]} onClose={this.onCloseCustomModal.bind(this, row.original.quoteid)}>
								Add {row.original.name}({row.original.userid}) to new Delivery team
								<hr/>
								<div id={row.original.userid}>
									<form name={row.original.userid} class="form-control" onSubmit={this.handleDeliveryFormSubmit}>
										{Object.keys(this.state.teams).map((team) => {
											return (
												<div>
													{(row.original.deliveryRole.findIndex(user => user.teamName == this.state.teams[team].teamName) == -1) ? (<div className="form-group">
														<input type="checkbox" id={team} name={row.original.userid} value={team} checked={this.state.deliveryTeams[row.original.userid] && this.state.deliveryTeams[row.original.userid].checked} onChange={this.handleCheckboxChange}/>
														<label><b>{this.state.teams[team].teamName}</b></label>
														<div className="row">
															{this.state.deliveryTeams.hasOwnProperty(row.original.userid) && this.state.deliveryTeams[row.original.userid].hasOwnProperty(team) ? obj.map((role) => {
																return (
																	<div className="col-md">
																		<input type="radio" name={row.original.userid + "_" + team} id={role} checked={this.state.deliveryTeams[row.original.userid][team] == role.code} value={role.code} onChange={this.handleRadioChange}/>
																		<label>{role.role}</label>
																	</div>
																)
															}) : ""}
														</div>
													</div>) : ""}
												</div>
											)
										})}
										<div className="form-group">
											<button type="submit" className="btn btn-success">Add Teams</button>
										</div>
									</form>
								</div>
							</Modal>
						</div>
					)
				}
			}]*/
		];

		if (data.length > 0) {
			this.setState({
				stateList: states.map(val => { return { value: val, label: val } }),
				stageList: stages.map(val => { return { value: val, label: val } }),
				ebissuesList: ebissues.map((val, index) => { return { value: index, label: val } }),
				subsidiesList: subsidies.map((val, index) => { return { value: index, label: val } }),
				vendorList: ["NA", "Boond", "Chemitech", "SmartRoof", "Aryav", "Zivah", "SDSL", "Oakridge", "ZunRoof", "SunEnergy", "Volks Energie", "Sirius", "Greenon", "GeoPower", "GM Trading", "Alien", "Adhunik"].map(val => { return { value: val, label: val } }),
				siteVisitOptions: ["Pending", "Completed", "Layout Shared", "Layout Approved", "Layout Rejected"].map(val => { return { value: val, label: val } }),
				viewData: data.map(val => val.stage === "Truncated" ? false : val).filter(val => val ? true : false),
				columns: columns.map(val => { let value = { ...val }; value.show = choosenColumn[value.accessor] === undefined ? true : choosenColumn[value.accessor]; return value; }), defaultColumns: columns,
				openCustomModal: {},
				columnCheckBoxStatus: choosenColumn,
				stage1List: Object.keys(newStages).map(val => ({ label: val, value: val })),
				netMeteringOptions: netMeteringMap.map(val => { return { value: val, label: val } })
			}, () => {
				this.handleColumnChange();
				if(selectedStage1) {
					this.setState({
						stage1Choosen: [ { label: selectedStage1, value: selectedStage1 } ],
						stage2Choosen: selectedStage2 ? [ { label: selectedStage2, value: selectedStage2 } ] : [],
						stateChoosen: (selectedStateList1 || []).map(val => ({ label: val, value: val })),
						cityChoosen: (selectedCityList1 || []).map(val => ({ label: val, value: val })),
						selectedVar
					}, () => this.applyFilter());
				}
				if(selectedVariable) {
					this.applyFilterVariable(selectedVariable, selectedStateList1 || [], selectedCityList1 || []);
				}
			})
		}
	}

	onOpenCustomModal(userid) {
		this.setState({
			openCustomModal: {
				[userid]: true
			}
		});
	};

	onCloseCustomModal(userid) {
		this.setState({
			openCustomModal: {
				[userid]: false
			}
		});
	};

	componentWillReceiveProps(props) {
		this.applyFilter();
	}

	handleMultiSelectChange = (selectedOption, name) => {
		console.log(selectedOption, name);
		switch (name) {
			case "stateChange":
				let {stateCityMap ={}} = this.props;
				let cityList = [];
				(selectedOption||[]).map((data)=>{
					cityList = cityList.concat(stateCityMap[data.label])
				})
				return this.setState({ stateChoosen: selectedOption, cityList : cityList.map((val)=>({label : val, value : val})) });
			case "stage1Change":
				let {newStages ={}} = this.props;
				let stage2List = [];
				(selectedOption||[]).map((data)=>{
					stage2List = stage2List.concat(newStages[data.label])
				})
				return this.setState({ stage1Choosen: selectedOption, stage2List : stage2List.map((val)=>({label : val, value : val})) });
			case "cityChange" : 
				return this.setState({ cityChoosen: selectedOption });
			case "stage2Change" : 
				return this.setState({ stage2Choosen: selectedOption });
			case "subsidyChange":
				return this.setState({ subsidyChoosen: selectedOption });
			case "stageChange":
				return this.setState({ stageChoosen: selectedOption });
			case "v1Change":
				return this.setState({ vendorChoosen: selectedOption });
			case "ebIssueChange":
				return this.setState({ ebissueChoosen: selectedOption });
			case "siteVisitChange":
				return this.setState({ siteVisitChoosen: selectedOption });
			case "netMeterChange":
				return this.setState({ netMeterChoosen: selectedOption });
			default:
				return;
		}
	}

	handleDateInputChange = (e) => {
		if (e.target.name === "startDate") {
			this.setState({
				[e.target.name]: e.target.value ? new Date(e.target.value) : 0
			})
		} else {
			this.setState({
				[e.target.name]: e.target.value ? new Date(e.target.value) : 9999999999999
			})
		}
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleColumnChange(index) {
		let indexChoosen = { ...this.state.columnCheckBoxStatus };
		indexChoosen[index] = this.state.columnCheckBoxStatus[index] === undefined ? false : !this.state.columnCheckBoxStatus[index];
		console.log(index, this.state.columnCheckBoxStatus)
		let newColumnView = this.state.columns.map((value) => {
			let val = { ...value };
			if (val.accessor === index) {
				val.show = indexChoosen[index] === undefined ? false : indexChoosen[index];
			}
			return val;
		});
		console.log(indexChoosen, newColumnView);
		this.setState({
			columnCheckBoxStatus: indexChoosen,
			columns: newColumnView
		})
		localStorage.setItem("column_operations", JSON.stringify(indexChoosen));
	}

	handleClickDownload() {
		this.setState({
			errorData: [],
			showError: false
		})
	}

	// HANDLE NOTIFICATIONS
	handleSelectNotifitionChange(e) {
		this.setState({
			notificationTitle: e.target.value,
			notificationText: this.state.notificationText ? this.state.notificationText : this.props.notificationMap["" + e.target.value].text,
			notificationTitleText: this.props.notificationMap["" + e.target.value].label
		})
	}

	handleNotificationChange(e) {
		this.setState({
			notificationText: e.target.value
		})
	}

	handleNotificationTitleCustomChange(e) {
		this.setState({
			notificationTitleText: e.target.value
		})
	}

	handleNotificationSubmit() {
		let emailArray = [];
		let notificationArray = [];
		let nameArray = [];
		let sitesArray = [];
		let phoneNumberArray = [];
		let allDataArray = [];
		let sendData = {};
		let errorArray = [];
		let text = this.state.notificationText;
		let title = this.state.notificationTitleText;
		let requestedBy = this.props.user.phoneNumber;
		let sendDate = createDateObj(new Date());
		console.log(text, title);

		if (text && title) {
			this.state.viewData.map((val, index) => {
				if (validateEmail(val.email)) {
					let data = {};
					data = {
						email: val.email,
						notificationId: val.userNotificationId,
						name: val.username,
						siteId: val.siteId,
						phoneNumber: val.phone
					}
					allDataArray.push(data);
					emailArray.push(val.email ? val.email : "NA@NA.com");
					notificationArray.push(val.userNotificationId);
					nameArray.push(val.username);
					sitesArray.push(val.siteId);
					phoneNumberArray.push(val.phone);
				} else {
					errorArray.push({ quoteid: val.quoteid });
				}
			});
			sendData = {
				emailList: emailArray,
				notificationList: notificationArray,
				nameList: nameArray,
				siteIdList: sitesArray,
				phoneNumberList: phoneNumberArray,
				text,
				title,
				sendDate,
				requestedBy,
				combineDataList: allDataArray
			}
			console.log(sendData);
			var lastSend = JSON.parse(JSON.stringify(sendData));
			this.props.addBulkNotification(lastSend);
			if (this.props.user.level == 1900) {
				const subject = "Bulk Notification sent by " + String(this.props.user.phoneNumber);
				this.props.sendEmailOnEdit(sendData, subject);
			}
			console.log(this.props.user.phoneNumber)
			sendData = {};
			this.setState({
				open: false,
				errorData: [...[], ...errorArray],
				showError: true
			});
		} else {
			this.setState({
				updateStatus: {
					status: true,
					text: "Text or Title Missing"
				}
			})
		}
	}



	onOpenModal() {
		this.setState({ open: true, updateStatus: { status: false, text: "" } });
	};

	onCloseModal() {
		this.setState({ open: false, updateStatus: { status: false, text: "" } });
	};

	onOpenColumnModal() {
		this.setState({ openColumn: true })
	}

	onCloseColumnModal() {
		this.setState({ openColumn: false })
	}


	applyFilter = () => {
		console.log("DONE");
		let stateChoosenArray = []
		if (this.state.stateChoosen) {
			stateChoosenArray = this.state.stateChoosen.map(val => val.value)
		}

		let cityChoosenArray = []
		if(this.state.cityChoosen){
			cityChoosenArray = this.state.cityChoosen.map(val => val.value)
		}

		let subsidyChoosenArray = [];
		if (this.state.subsidyChoosen) {
			subsidyChoosenArray = this.state.subsidyChoosen.map(val => val.label)
		}

		let stageChoosenArray = [];
		if (this.state.stageChoosen) {
			stageChoosenArray = this.state.stageChoosen.map(val => val.value)
		}

		let vendorChoosenArray = [];
		if (this.state.vendorChoosen) {
			vendorChoosenArray = this.state.vendorChoosen.map(val => val.value)
		}

		let ebissueChoosenArray = [];
		if (this.state.ebissueChoosen) {
			ebissueChoosenArray = this.state.ebissueChoosen.map(val => val.value)
		}

		let siteVisitChoosenArray = [];
		if (this.state.siteVisitChoosen) {
			siteVisitChoosenArray = this.state.siteVisitChoosen.map(val => val.value)
		}

		let stage1ChoosenArray = [];
		if(this.state.stage1Choosen) {
			stage1ChoosenArray = this.state.stage1Choosen.map(val => val.value)
		}

		let stage2ChoosenArray = [];
		if(this.state.stage2Choosen) {
			stage2ChoosenArray = this.state.stage2Choosen.map(val => val.value)
		}

		let netMeterChoosenArray = [];
		if(this.state.netMeterChoosen) {
			netMeterChoosenArray = this.state.netMeterChoosen.map(val => val.value)
		}

		let startPercentage = this.state.startPercentage;
		let endPercentage = this.state.endPercentage;
		console.log(this.state.startDate, this.state.endDate);
		console.log("cityChosenArray",cityChoosenArray);
		let viewData = this.props.data.map(value => {
			try {
				let stageVariables = JSON.parse(value.stageVariables) || {};
				if (checkExist(siteVisitChoosenArray, value.siteVisit) && checkExist(stateChoosenArray, value.stateD) && dateRange(value.salesdate, this.state.startDate, this.state.endDate) && percentageRange(value.paidPercentage || 0, startPercentage, endPercentage) && checkArrayExist(ebissueChoosenArray, value.ebIssueAllList) && checkExist(vendorChoosenArray, value.vendor) && checkExist(subsidyChoosenArray, value.subsidyD) && (value.stage !== "Truncated" || (value.stage === "Truncated" && stageChoosenArray.indexOf("Truncated") > -1)) && (this.state.rmsInstalled ? value.rmsInstalled == this.state.rmsInstalled : true) && (this.state.rmsStatus ? value.rmsStatus == this.state.rmsStatus : true) && (this.state.zunPrime ? value.zunPrime == this.state.zunPrime : true) && checkExist(stageChoosenArray, value.stage) && checkExist(cityChoosenArray, value.city) && checkExist(stage1ChoosenArray, value.stage1)  && checkExist(stage2ChoosenArray, value.stage2)   && checkExist(netMeterChoosenArray, value.netMeteringStage) && (!this.state.selectedVar || stageVariables[this.state.selectedVar])) {
					return value;
				}
				return false;
			}
			catch(error) {
				return false;
			}
		}).filter(val => val ? true : false);
		this.setState({
			viewData
		})
	}
	
	applyFilterVariable = (variableName, stateList = [], cityList = []) => {
		let viewData = this.props.data.map(value => {
			if(!value.stageVariables) return false;
			try {
				let stageVariables = JSON.parse(value.stageVariables) || {};
				if(stageVariables[variableName] && checkExist(stateList, value.stateD) && checkExist(cityList, value.city)) return value;
				return false;
			}
			catch(error) {
				return false;
			}
		}).filter(val => val ? true : false);
		this.setState({
			viewData
		})
	}

	downloadData = () => {
		let dataToDownload = JSON.parse(JSON.stringify(this.state.viewData));
		dataToDownload.forEach(function (rowData) {
			rowData.netMeterDate = rowData.netMeterDate ? changeToViewDate(rowData.netMeterDate) : '';
			rowData.invoiceDate = rowData.invoiceDate ? changeToViewDate(rowData.invoiceDate) : "";
			rowData.refDate = (rowData.refDate && rowData.refDate !== "NA") ? changeToViewDate(rowData.refDate) : "";
			rowData.salesdate = rowData.salesdate ? changeToViewDate(rowData.salesdate) : "";
			rowData.structureExpectedDate = rowData.structureExpectedDate ? changeToViewDate(rowData.structureExpectedDate) : "";
			rowData.panelExpectedDate = rowData.panelExpectedDate ? changeToViewDate(rowData.panelExpectedDate) : "";
			rowData.invertorExpectedDate = rowData.invertorExpectedDate ? changeToViewDate(rowData.invertorExpectedDate) : "";
			rowData.commissioningDate = rowData.commissioningDate ? changeToViewDate(rowData.commissioningDate) : "";
			rowData.approvalDate = rowData.approvalDate ? changeToViewDate(rowData.approvalDate) : "";
			rowData.totalCallDuration = rowData.totalCallDuration ? `${parseInt(rowData.totalCallDuration / 60)}m ${rowData.totalCallDuration % 60}s` : "";
			rowData.netMeteringAppliedDate = rowData.netMeteringAppliedDate ? changeToViewDate(rowData.netMeteringAppliedDate) : '';
			rowData.firstLayoutSharedDate = rowData.firstLayoutSharedDate ? changeToViewDate(rowData.firstLayoutSharedDate) : '';
            rowData.ebIssueAppliedDate = rowData.ebIssueAppliedDate ? changeToViewDate(rowData.ebIssueAppliedDate) : '';
            rowData.ebIssueResolutionDate = rowData.ebIssueResolutionDate ? changeToViewDate(rowData.ebIssueResolutionDate) : '';
            rowData.loanIssueDate = rowData.loanIssueDate ? changeToViewDate(rowData.loanIssueDate) : '';
            rowData.subsidyAppliedDate = rowData.subsidyAppliedDate ? changeToViewDate(rowData.subsidyAppliedDate) : '';
            rowData.subsidyApprovalDate = rowData.subsidyApprovalDate ? changeToViewDate(rowData.subsidyApprovalDate) : '';
            rowData.payment49Date = rowData.payment49Date ? changeToViewDate(rowData.payment49Date) : '';
            rowData.payment99Date = rowData.payment99Date ? changeToViewDate(rowData.payment99Date) : '';
			rowData.v2HandoverDate = rowData.v2HandoverDate ? changeToViewDate(rowData.v2HandoverDate) : '';
		});
		this.setState({ downloadData: dataToDownload }, () => {
			console.log('Download Clicked');
			this.csvLink.current.link.click();
		})
	}

	render() {
		let { handleMultiSelectChange, applyFilter, handleDateInputChange, handleInputChange } = this;
		let { loading } = this.props;
		let { stateList, stageList, vendorList, ebissuesList, subsidiesList, columnCheckBoxStatus, siteVisitOptions,cityList, stage1List, stage2List, netMeteringOptions } = this.state;
		const style = {
			white: {
				backgroundColor: 'white'
			},
			green: {
				backgroundColor: 'green'
			}
		}
		return (
			<div>
				<div>
					<Modal open={this.state.open} onClose={this.onCloseModal} center>
						<div style={styleSheet.commentForm} className="form-group">
							<div className="container">
								{this.state.updateStatus && this.state.updateStatus.status ?
									<div className="alert alert-warning" role="alert">
										{this.state.updateStatus.text}
									</div> : ""}
							</div>
							<label htmlFor="notificationTitle">Notification:</label>
							<br />
							<select className="custom-select" value={this.state.notificationTitle ? this.state.notificationTitle : ""} onChange={this.handleSelectNotifitionChange}>
								<option value={-1}>Select Notification Title</option>
								{
									this.props.notificationMap ? this.props.notificationMap.map((data, index) => {
										return (
											<option key={index} value={index}>{data.label}</option>
										)
									}) : ""
								}
							</select>
							<br />
							<label htmlFor="notificationTitle"></label>
							<input value={this.state.notificationTitleText} onChange={this.handleNotificationTitleCustomChange} className="form-control" rows="5" />
							<label htmlFor="notificationText"></label>
							<textarea value={this.state.notificationText} onChange={this.handleNotificationChange} className="form-control" rows="5" id="comment"></textarea>
							<div style={styleSheet.commentFormButton}><button style={styleSheet.commentFormButton} className="btn btn-success" onClick={this.handleNotificationSubmit}>Submit</button></div>
						</div>
					</Modal>
					{
						<Modal open={this.state.openColumn} onClose={this.onCloseColumnModal} center>
							<div style={{ width: '600px', height: '500px', display: 'flex' }}>
								<div className="row">
									{
										this.state.columns.map((val, index) => {
											if (index) {
												return (
													<SalesViewCheckBox
														key={val.accessor}
														showDefault={false}
														columnCheckBoxStatus={columnCheckBoxStatus[val.accessor]}
														handleColumnChange={this.handleColumnChange}
														index={val.accessor}
														label={val.customName}
													/>
												)
											} else {
												return "";
											}
										})
									}
								</div>
							</div>
						</Modal>
					}
					<div style={styleSheet.divStyle} className="filter_deals">
					{/* <UncontrolledAlert color="danger">The data here may be an hour old. Please check "Last Updated Time" for the exact time of updated data. You will always get the most recent and updated data in QuoteId view. Data here automatically gets updated every hour by itself, you will just have to refresh the page. Please click on "Get Recent Data" button if, and only if, you require most recent data. It may, however, significantly affect the loading time.</UncontrolledAlert> */}
						<div>
							<button onClick={this.downloadData} className="btn btn-success">Download</button>
							{
								(this.props.user.level >= 1900 || this.props.user.phoneNumber == '+918448389955') ? <CSVLink className="hidden" ref={this.csvLink} filename={"Deals_View_Data.csv"} data={this.state.downloadData} headers={this.state.columns.map((val, index) => { return val.show && val.accessor != 'select' ? { label: val.customName, key: val.accessor } : false }).filter(val => val ? true : false)} type="button" />
									: ""
							}
							{
								this.props.user.level >= 1900 ? <button type="button" style={{ margin: '0 10px' }} className="btn btn-success" onClick={this.onOpenModal}>
									Send Notifications
								</button> : ""
							}
							{
								this.props.user.level >= 1900 && this.state.errorData.length > 0 && this.state.showError ? <CSVLink onClick={this.handleClickDownload} filename={"Deals_View_Error_Data.csv"} data={this.state.errorData} headers={csvErrorCSV} type="button" className="btn btn-info">
									Download Error
								</CSVLink> : ""
							}
							<button type="button" style={{ margin: '0 10px' }} className="btn btn-success" onClick={() => this.props.reloadDealsData()} disabled={this.props.reloadDisabled}>
								Get Recent Data
							</button>
							Last Updated Time : {this.props.lastUpdatedTime}
						</div>
						<Filter
							siteVisitOptions={siteVisitOptions}
							stateList={stateList}
							cityList = {cityList}
							stageList={stageList}
							vendorList={vendorList}
							ebissuesList={ebissuesList}
							subsidiesList={subsidiesList}
							handleDateInputChange={handleDateInputChange}
							handleInputChange={handleInputChange}
							handleMultiSelectChange={handleMultiSelectChange}
							applyFilter={applyFilter}
							stage1List={stage1List}
							stage2List={stage2List}
							netMeteringOptions={netMeteringOptions}
						/>
					</div>
				</div>
				{/*<div className="deals_table_head">
					<i className="fas fa-cog fa-2x" onClick={this.onOpenModal}></i>
				</div>*/}
				<ReactTable
					loading={loading}
					filterable={true}
					showPagination={true}
					sortable={true}
					data={this.state.viewData}
					columns={this.state.columns}
					style={
						{
							textAlign: 'center'
						}
					}
					getTrProps={(state, rowInfo, column, instance) => rowInfo ? rowInfo.original.ebIssueAllList.reduce((a, b) => ebIssueMap[a] || ebIssueMap[b], false) && rowInfo.original.financingIssue === "Yes" ?
						{
							style: {
								backgroundColor: '#c8d7aa'
							}
						} : rowInfo.original.ebIssueAllList.reduce((a, b) => ebIssueMap[a] || ebIssueMap[b], false) ? {
							style: {
								backgroundColor: '#f0e4c8'
							}
						} : rowInfo.original.financingIssue === "Yes" ? {
							style: {
								backgroundColor: '#bebebe'
							}
						} : {
									style: {
										backgroundColor: 'white'
									}
								} : {
							style: {
								backgroundColor: 'white'
							}
						}
					}
					defaultSorted={[{ id: "salesdate", desc: true }]}
					defaultFilterMethod={(filter, row) => {
						if(filter.id == "subsidyStatus") return String(row[filter.id]).toLowerCase() == filter.value.toLowerCase();
						return String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1
					}} />
			</div>
		);
	}
}
