import React, { Component } from 'react';
import { styleSheet } from '../styles/styleSheet'
import moment from 'moment';

export class OrderStageView extends Component {
    constructor(props) {
        super(props);
        //this.selectedOption = {};	
        this.state = {
            projectPreparationDate: '',
            productDeliveryDate: ''
        };
    }

    componentDidMount() {
        let { deliveredDate, ebIssueDate, inverterDate, layoutApprovalDate, loanIssueDate, netMeteringDate, panelDate, structureDate, subsidyApprovalDate, subsidyAppliedDate, p1Done, p2Done, salesDate } = this.props.data || {};
        let projectPreparationDate = '', productDeliveryDate = '';
        if(ebIssueDate && loanIssueDate && subsidyAppliedDate && p1Done && layoutApprovalDate && salesDate) {
            projectPreparationDate = Math.max(ebIssueDate, loanIssueDate, subsidyAppliedDate, p1Done, layoutApprovalDate, salesDate);
        }
        let data = this.props.data;
        productDeliveryDate = data.inverterDate && data.subsidyApprovalDate && data.p2Done && data.structureDate && data.panelDate ? Math.max(data.inverterDate, data.subsidyApprovalDate, data.p2Done, data.structureDate, data.panelDate) : "";
        this.setState({ projectPreparationDate: projectPreparationDate, productDeliveryDate })
        console.log('orderstageview', this.props.data);
    }

    getDiffInDays = (date1, date2, fromKey) => {
        if(!date1 || !date2) return "";
        let daysDiff = moment(+date1).diff(moment(+date2), 'days');
        daysDiff = daysDiff > 0 ? daysDiff : 0;
        return `(${daysDiff} days from ${fromKey})`;
    }


    render() {
        const style = {
            hide: {
                display: 'none'
            },
            show: {
                display: ''
            }
        }

        let { data = {} } = this.props;


        return (
            <div className="row row_data">
                <div className="col-12">
                    <div className="page-title">
                        <div style={styleSheet.titlePage}>
                            <div style={styleSheet.titlePage.quote}>
                                Stage
								</div>
                            <div style={styleSheet.titlePage.actions}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="page_data page_data__background">
                        <div className="row row_data">
                            <div className="col-md-4 page_data__1">
                                <div className="row_head">
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Project Preparation</span>
        <span className="row_data__key col-6">{this.state.projectPreparationDate ? moment(+this.state.projectPreparationDate).format("DD MMM, YYYY") : ""}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Confirmation Date</span>
        <span className="row_data__key col-6">{data.confirmationDate ? moment(+data.confirmationDate).format("DD MMM, YYYY") : ""}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">EB Date</span>
        <span className="row_data__key col-6">{data.ebIssueDate ? moment(+data.ebIssueDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.ebIssueDate, data.salesDate, "Sale")}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Loan Date</span>
        <span className="row_data__key col-6">{data.loanIssueDate ? moment(+data.loanIssueDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.loanIssueDate, data.salesDate, "Sale")}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Subsidy Applied</span>
        <span className="row_data__key col-6">{data.subsidyAppliedDate ? moment(+data.subsidyAppliedDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.subsidyAppliedDate, data.ebIssueDate, "EB")}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Payment Completion</span>
        <span className="row_data__key col-6">{data.p1Done ? moment(+data.p1Done).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.p1Done, data.salesDate, "Sale")}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Layout Approval</span>
        <span className="row_data__key col-6">{data.layoutApprovalDate ? moment(+data.layoutApprovalDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.layoutApprovalDate, data.p1Done, "Payment")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 page_data__2">
                                <div className="row_head">
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Product Delivery</span>
        <span className="row_data__key col-6">{this.state.productDeliveryDate ? moment(+this.state.productDeliveryDate).format("DD MMM, YYYY") : ""}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Inverter</span>
        <span className="row_data__key col-6">{data.inverterDate ? moment(+data.inverterDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.inverterDate, this.state.projectPreparationDate, 'Prep')}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Subsidy Approval</span>
        <span className="row_data__key col-6">{data.subsidyApprovalDate ? moment(+data.subsidyApprovalDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.subsidyApprovalDate, this.state.projectPreparationDate, 'Prep')}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Payment Completion Date</span>
        <span className="row_data__key col-6">{data.p2Done ? moment(+data.p2Done).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.p2Done, data.inverterDate, 'Inverter')}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Structure</span>
        <span className="row_data__key col-6">{data.structureDate ? moment(+data.structureDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.structureDate, data.p2Done, 'Payment')}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Panel</span>
        <span className="row_data__key col-6">{data.panelDate ? moment(+data.panelDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.panelDate, data.p2Done, 'Payment')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 page_data__3">
                                <div className="row_head">
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Installation</span>
        <span className="row_data__key col-6">{data.commissioningDate ? moment(+data.commissioningDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.commissioningDate, this.state.productDeliveryDate, 'Delivery')}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Net metering</span>
        <span className="row_data__key col-6">{data.netMeteringDate ? moment(+data.netMeteringDate).format("DD MMM, YYYY") : ""} {this.getDiffInDays(data.netMeteringDate, this.state.commissioningDate, 'Delivery')}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row_data">
                                    <span className="row_data__key col-6">Delivered</span>
        <span className="row_data__key col-6">{data.deliveredDate ? moment(+data.deliveredDate).format("DD MMM, YYYY") : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}